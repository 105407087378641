<template>
  <a class="the-cta-button">
    <div>
      <slot />
    </div>
  </a>
</template>

<style lang="scss">
a.the-cta-button {
  display: inline-block;
  margin: auto;
  border: 2px solid white;
  padding: 1px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  text-decoration: none;
  div {
    padding: 0.75rem 6rem;
    border: 1px solid white;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
  }

  &:hover {
    border-color: rgb(150, 150, 150);

    div {
      border-color: rgb(150, 150, 150);
    }
  }
}
</style>
